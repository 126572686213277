<template>
    <div class="pattern">
   
         
        <div class="">
            <router-view></router-view>
        </div>

   

    </div>
</template>

<script>


//import Sidebar from "./SidebarT.vue"

export default {
    components: {
     // Sidebar
    },
}

</script>

<style>

    .dashboard_conteiner {
        margin-left:250px!important;
    }

</style>